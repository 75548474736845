<template>
  <div>
    <list
      ref="refAccountBankList"
      sort-by="proveedorTiCuentaBancariaId"
      refetch-records-name="cuentaBancarias"
      key-field="ProveedorTiCuentaBancariaId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchAccountBankProviders"
      :delete-method="deleteAccountBankProvider"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'

export default {
  components: {
    List,
  },
  setup() {
    const refAccountBankList = ref(null)
    const {
      fetchAccountBankProviders,
      deleteAccountBankProvider,
    } = tiService()
    const filters = ref([
      {
        type: 'select',
        name: 'bancoId',
        label: i18n.t('AppTI.filters.bank'),
        value: '',
        options: [],
        domainName: 'Bancos',
      },
      {
        type: 'select',
        name: 'proveedorTiId',
        label: i18n.t('AppTI.filters.provider'),
        value: '',
        options: [],
        domainName: 'proveedoresTi',
      },
    ])
    const tableColumns = [
      {
        key: 'proveedorTi.nombre',
        label: i18n.t('AppTI.accountBank.provider'),
        sortable: false,
      },
      {
        key: 'banco.nombre',
        label: i18n.t('AppTI.accountBank.bankName'),
        sortable: false,
      },
      {
        key: 'razonSocial',
        label: i18n.t('AppTI.accountBank.businessName'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'read',
        aclResource: 'CuentaBancaria',
        routeName: 'apps-account-bank-edit',
        params: ['proveedorTiCuentaBancariaId'],
        icon: 'Edit2Icon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'CuentaBancaria',
        params: ['proveedorTiCuentaBancariaId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppTI.actions.newAccount'),
        aclAction: 'create',
        aclResource: 'CuentaBancaria',
        routeName: 'apps-account-bank-add',
        icon: 'PlusCircleIcon',
      },
    ])

    return {
      refAccountBankList,
      fetchAccountBankProviders,
      deleteAccountBankProvider,
      filters,
      tableColumns,
      tableActions,
      actions,
    }
  },
}
</script>
